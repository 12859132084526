export default [
  {
    path: '/sparepart-orders',
    name: 'SparepartOrders',
    component: () => import('@/views/Spareparts/Orders/Index.vue'),
    meta: {
      pageTitle: 'Yedek Parça Siparişleri',
      breadcrumb: [
        {
          text: 'Listele',
          active: true,
        },
      ],
      resource: 'SPAREPART_ORDERS',
      action: 'read',
    },
  },
  {
    path: '/sparepart-orders/search',
    name: 'SparepartOrdersSearch',
    component: () => import('@/views/Spareparts/Orders/SearchCustomer.vue'),
    meta: {
      pageTitle: 'Yedek Parça Siparişleri',
      breadcrumb: [
        {
          text: 'Listele',
          to: '/sparepart-orders',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'SPAREPART_ORDERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/sparepart-orders/add/:id',
    name: 'SparepartOrdersAdd',
    component: () => import('@/views/Spareparts/Orders/Add.vue'),
    meta: {
      pageTitle: 'Yedek Parça Siparişleri',
      breadcrumb: [
        {
          text: 'Listele',
          to: '/sparepart-orders',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SPAREPART_ORDERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/sparepart-orders/add-to-work-orders',
    name: 'SparepartOrdersAddToWorkOrders',
    component: () => import('@/views/Spareparts/Orders/AddToWorkOrders.vue'),
    meta: {
      pageTitle: 'Yedek Parça Siparişleri',
      breadcrumb: [
        {
          text: 'Listele',
          to: '/sparepart-orders',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SPAREPART_ORDERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/sparepart-orders/edit/:id',
    name: 'SparepartOrdersEdit',
    component: () => import('@/views/Spareparts/Orders/Edit.vue'),
    meta: {
      pageTitle: 'Yedek Parça Siparişleri',
      breadcrumb: [
        {
          text: 'Listele',
          to: '/sparepart-orders',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SPAREPART_ORDERS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/sparepart-orders/import/:id',
    name: 'SparepartOrdersAdd',
    component: () => import('@/views/Spareparts/Orders/Import.vue'),
    meta: {
      pageTitle: 'Yedek Parça Siparişleri',
      breadcrumb: [
        {
          text: 'Listele',
          to: '/sparepart-orders',
          active: false,
        },
        {
          text: 'Veri Aktarımı',
          active: true,
        },
      ],
      resource: 'SPAREPART_ORDERS_ADD',
      action: 'read',
    },
  },
]
