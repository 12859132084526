import axiosIns from '@/libs/axios'

const ROOT_URL = '/RPA/Tbl_IsEmirleri'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataLoading: false,
    filterData: {
      keywords: null,
      brand: null,
      location: null,
      user: null,
      randevu_starihi: null,
      randevu_etarihi: null,
      DB_Yazdirma_sTarihi: null,
      DB_Yazdirma_eTarihi: null,
    },
    filterParams: {
      brands: [],
      locations: [],
      users: [],
    },
    dataItem: {
      id: null,
      Belge_Tipi: null,
      Randevu_No: null,
      Musteri_Adi: null,
      TC_Kimlik: null,
      Telefon: null,
      E_Posta: null,
      Marka: null,
      Arac_Model: null,
      Model_Yili: null,
      Plaka: null,
      Islem_Tipi: null,
      tarih_saat: null,
      Randevu_Tarihi: null,
      Lokasyon: null,
      Durum: null,
      Danisman: null,
      Temas_Tipi: null,
      Musteri_Talebi: null,
      KVKK: null,
      Gorusme_Notu: null,
      Il_Ilce: null,
      Is_Emri_No: null,
      Sase_No: null,
      Motor_No: null,
      Km: null,
      DB_Yazdirma_Tarihi: null,
      transfer_error: null,
      transferred: null,
    },
    statistic: {
      opened: 0,
      closed: 0,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    statistic(state) {
      return state.statistic
    },
    filterParams(state) {
      return state.filterParams
    },
    dataItem(state) {
      return state.dataItem
    },
    dataFilter(state) {
      return state.filterData
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLoading(state) {
      return state.dataLoading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_STATISTIC(state, data) {
      state.statistic = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_FILTER_PARAMS(state, data) {
      if (data.brands) {
        state.filterParams.brands = data.brands
      }
      if (data.locations) {
        state.filterParams.locations = data.locations
      }
      if (data.users) {
        state.filterParams.users = data.users
      }
    },
    RESET_FILTER_DATA(state) {
      state.filterData = {
        keywords: null,
        brand: null,
        location: null,
        user: null,
        randevu_starihi: null,
        randevu_etarihi: null,
        DB_Yazdirma_sTarihi: null,
        DB_Yazdirma_eTarihi: null,
      }
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        Belge_Tipi: null,
        Randevu_No: null,
        Musteri_Adi: null,
        TC_Kimlik: null,
        Telefon: null,
        E_Posta: null,
        Marka: null,
        Arac_Model: null,
        Model_Yili: null,
        Plaka: null,
        Islem_Tipi: null,
        tarih_saat: null,
        Randevu_Tarihi: null,
        Lokasyon: null,
        Durum: null,
        Danisman: null,
        Temas_Tipi: null,
        Musteri_Talebi: null,
        KVKK: null,
        Gorusme_Notu: null,
        Il_Ilce: null,
        Is_Emri_No: null,
        Sase_No: null,
        Motor_No: null,
        Km: null,
        DB_Yazdirma_Tarihi: null,
        transfer_error: null,
        transferred: null,
      }
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          commit('SET_DATA_STATISTIC', response.data.statistic)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    getFilterParams({ commit }) {
      axiosIns
        .post(`${ROOT_URL}/filterParams`)
        .then(response => {
          commit('SET_FILTER_PARAMS', response.data)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify({
        where: {
          'tbl_IsEmirleri.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          commit('SET_DATA_LOADING', false)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    resetFilterData({ commit }) {
      commit('RESET_FILTER_DATA')
    },
  },
}
