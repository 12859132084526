import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    cars: [],
    carsCount: 0,
    car: {
      id: null,
      hardware: null,
      myear: null,
      license_plate: null,
      chassis: null,
      engine: null,
      license_number: null,
      purchase_invoice: null,
      purchase_invoice_no: null,
      sales_invoice: null,
      sales_invoice_no: null,
      policy_no: null,
      insurance_company: null,
      kasko: null,
      trafik: null,
      muayene: null,
      egzoz: null,
      warranty_start: null,
      warranty_end: null,
      register_date: null,
      id_com_swap_brand: null,
      id_com_swap_model: null,
      id_com_color: null,
      id_com_fuel: null,
      id_com_gear: null,
      id_com_customer: null,
      detailForm: false,
      submitStatus: false,
    },
    carSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getCars(state) {
      return state.cars
    },
    getCarsCount(state) {
      return state.carsCount
    },
    getCar(state) {
      return state.car
    },
    getCarSaveStatus(state) {
      return state.carSave
    },
  },
  mutations: {
    CARS_LIST(state, data) {
      state.cars = data
    },
    CAR_VIEW(state, data) {
      state.car = data
    },
    CARS_COUNT(state, data) {
      state.carsCount = data
    },
    CAR_SAVE(state, data) {
      state.carSave = data
    },
    RESET_CARS_LIST(state) {
      state.cars = []
    },
    CAR_RESET(state) {
      state.car = {
        id: null,
        hardware: null,
        myear: null,
        license_plate: null,
        chassis: null,
        engine: null,
        license_number: null,
        purchase_invoice: null,
        purchase_invoice_no: null,
        sales_invoice: null,
        sales_invoice_no: null,
        policy_no: null,
        insurance_company: null,
        kasko: null,
        trafik: null,
        muayene: null,
        egzoz: null,
        warranty_start: null,
        warranty_end: null,
        register_date: null,
        id_com_swap_brand: null,
        id_com_swap_model: null,
        id_com_color: null,
        id_com_fuel: null,
        id_com_gear: null,
        id_com_customer: null,
        detailForm: false,
        submitStatus: false,
      }
    },
  },
  actions: {
    carsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/cars', data)
        .then(response => {
          commit('CARS_LIST', response.data.data)
          commit('CARS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    carView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_cars.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/cars', data)
        .then(response => {
          commit('CAR_VIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    carSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/cars/saveData', data)
        .then(response => {
          commit('CAR_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    carReset({ commit }) {
      commit('CAR_RESET')
    },
  },
}
